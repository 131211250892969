import './App.css';
import { PccBgcForm } from './Components/PccBgcForm';

function App() {
  return (
    <div className="App">
   <PccBgcForm/>
    </div>
  );
}

export default App;
